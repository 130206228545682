import React, { useEffect, useState } from 'react';
import { generateDemoEvents } from '../utils/helper';
import { DateTime } from 'luxon';
import Kalend, { CalendarView, OnEventDragFinish } from 'kalend';
import 'kalend/dist/styles/index.css';
import { useAppContext } from '../context/state';

const CalendComponent = (props: any) => {
  const appContext = useAppContext();
  const [demoEvents, setDemoEvents] = useState([]);

  // Create and load demo events
  useEffect(() => {
    console.log(appContext.currentCalendar);
    setDemoEvents(generateDemoEvents(appContext.currentCalendar));
  }, [appContext.currentCalendar]);

  const onNewEventClick = (data: any) => {
    const msg = `New event click action\n\n Callback data:\n\n${JSON.stringify({
      hour: data.hour,
      day: data.day,
      startAt: data.startAt,
      endAt: data.endAt,
      view: data.view,
      event: 'click event ',
    })}`;
    console.log(msg);
  };

  // Callback for event click
  const onEventClick = (data: any) => {
    const msg = `Click on event action\n\n Callback data:\n\n${JSON.stringify(
      data
    )}`;
    console.log(msg);
  };

  // Callback after dragging is finished
  const onEventDragFinish: OnEventDragFinish = (
    prev: any,
    current: any,
    data: any
  ) => {
    setDemoEvents(data);
  };

  return (
    <Kalend
      kalendRef={props.kalendRef}
      onNewEventClick={onNewEventClick}
      initialView={CalendarView.WEEK}
      disabledViews={[]}
      onEventClick={onEventClick}
      events={demoEvents}
      initialDate={new Date().toISOString()}
      hourHeight={50}
      weekDayStart='Sunday'
      // showWeekNumbers={true}
      timezone={'America/Los_Angeles'}
      timeFormat='12'
      // draggingDisabledConditions={{
      //   summary: 'Computers',
      //   allDay: false,
      //   color: 'pink',
      // }}
      onEventDragFinish={onEventDragFinish}
      onStateChange={props.onStateChange}
      selectedView={props.selectedView}
      showTimeLine={true}
      isDark={false}
      autoScroll={true}
      // disabledDragging={true}
      colors={{
        light: {
          primaryColor: '#137eff',
        },
        dark: {
          primaryColor: '#137eff',
        },
      }}
    />
  );
};

export default CalendComponent;
