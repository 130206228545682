import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/theme.css';
import './styles/globals.scss';
import MainPage from './pages/main';
import Full from './pages/full';
import { Route } from 'react-router';
import { AppWrapper } from './context/state';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <AppWrapper>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<MainPage />} />
          <Route path={'/full'} element={<Full />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </AppWrapper>
);
