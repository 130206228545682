import React from 'react';
import Layout from '../components/Layout';
import CalendComponent from '../components/Calendar';

const MainPage = () => {
  return (
    <Layout>
      <div className='vh-100 bg-white'>
        <CalendComponent />
      </div>
    </Layout>
  );
};

export default MainPage;
