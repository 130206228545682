import { createContext, useContext, useState } from 'react';
import { CALENDARS, CALENDAR_HOME } from '../utils/helper';

const AppContext = createContext();

export function AppWrapper({ children }) {
  let [currentCalendar, setCurrentCalendar] = useState(CALENDAR_HOME)

  return (
    <AppContext.Provider value={{currentCalendar, setCurrentCalendar}}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}