import { DateTime } from 'luxon';
import faker from 'faker';
import { Card } from 'react-bootstrap';

export const CALENDAR_HOME = 'home';

export const CALENDARS: any = {
  all_company: {
    label: 'all_company',
    color: '#e5f5e1',
    borderColor: '#5bc146',
    recurringEvents: [
      {
        hour: 8,
        minute: 0,
        duration: 60,
        weekday: 3,
        title: 'Company All-Hands',
      },
      {
        hour: 0,
        minute: 0,
        duration: 1439,
        weekday: 6,
        title: 'Recharge Day',
      },
    ]
  },
  acme_customer: {
    label: 'acme_customer',
    color: '#f9ffe3',
    recurringEvents: [
      {
        hour: 13,
        minute: 0,
        duration: 60,
        weekday: 4,
        title: 'Acme Customer Check-in',
      }
    ]
  },
  social: {
    label: 'social',
    color: '#ffe3e6',
    recurringEvents: [
      {
        hour: 16,
        minute: 0,
        duration: 120,
        weekday: 5,
        title: 'Happy Hour',
      }
    ]
  },
  product: {
    label: 'product',
    color: '#fff8e3',
    recurringEvents: [
      {
        hour: 8,
        minute: 0,
        duration: 60,
        weekday: 4,
        title: 'Product All-hands',
      },
    ]
  },
  engineering: {
    label: 'engineering',
    color: '#fff1e3',
    recurringEvents: [
      {
        hour: 8,
        minute: 0,
        duration: 60,
        weekday: 1,
        title: 'Engineering All-hands',
      },
    ]
  },
  managers: {
    label: 'managers',
    color: '#ffe3f7',
    recurringEvents: [
      {
        hour: 11,
        minute: 0,
        duration: 60,
        weekday: 3,
        title: 'Managers Weekly',
      },
    ]
  },
  epd_managers: {
    label: 'epd_managers',
    color: '#d9eaff',
    recurringEvents: [
      {
        hour: 9,
        minute: 0,
        duration: 60,
        weekday: 2,
        title: 'EPD Managers Weekly',
      },
    ]
  },
  engr_managers: {
    label: 'engr_managers',
    color: '#e4faff',
    recurringEvents: [
      {
        hour: 11,
        minute: 0,
        duration: 60,
        weekday: 1,
        title: 'Eng Managers Weekly',
      },
    ]
  },
  epd_pretax: {
    label: 'epd_pretax',
    color: '#e8e4ff',
    recurringEvents: [
      {
        hour: 10,
        minute: 0,
        duration: 60,
        weekday: 2,
        title: 'Pretax Weekly Check-in',
      },
      {
        hour: 11,
        minute: 0,
        duration: 60,
        weekday: 4,
        title: 'Pretax Sprint Retro',
      },
      {
        hour: 10,
        minute: 0,
        duration: 60,
        weekday: 5,
        title: 'Pretax Technical Reviews',
      },
      {
        hour: 12,
        minute: 0,
        duration: 60,
        weekday: 1,
        title: 'Pretax Grooming',
      }
    ]
  },
  jordan: {
    label: 'jordan',
    color: '#d9eaff',
    recurringEvents: [
      {
        hour: 14,
        minute: 0,
        duration: 60,
        weekday: 3,
        title: 'Kuldeep / Jordan 1:1',
      },
    ]
  },
  sadio: {
    label: 'sadio',
    color: '#d9eaff',
    recurringEvents: [
      {
        hour: 15,
        minute: 0,
        duration: 60,
        weekday: 4,
        title: 'Kuldeep / Sadio 1:1',
      },
    ]
  },
  thiago: {
    label: 'thiago',
    color: '#d9eaff',
    recurringEvents: [
      {
        hour: 14,
        minute: 0,
        duration: 60,
        weekday: 1,
        title: 'Kuldeep / Thiago 1:1',
      },
    ]
  },
  karun: {
    label: 'karun',
    color: '#d9eaff',
    recurringEvents: [
      {
        hour: 13,
        minute: 0,
        duration: 60,
        weekday: 2,
        title: 'Kuldeep / Karun 1:1',
      },
    ]
  },
}

const Event = (props: any) => {
  return (
    <div className='event-card w-100'>
      <div className='event-body py-1 px-2'>
        <div className="text-xs text-muted text-info">
          <span>#{props.label}</span>
        </div>
        <div className='event-title text-smd'>{props.title}</div>
        {/* <div className="text-xs text-muted">
          <span>{props.startDate.toLocaleString(DateTime.TIME_SIMPLE)} - {props.endDate.toLocaleString(DateTime.TIME_SIMPLE)}</span>
        </div> */}
        {/* <div className='event-subtitle text-muted text-sm'>Card Subtitle</div> */}
      </div>
    </div>
  );
};

export const generateDemoEvents = (
  currentCalendar: string
) => {
  const today = DateTime.now();
  const events: any = [];

  if(currentCalendar == CALENDAR_HOME){
    let events = [];
    for(let key of Object.keys(CALENDARS)){
      const t = generateEvents(CALENDARS[key])
      events.push(...t);
    }
    return events;
  } else {
    return generateEvents(CALENDARS[currentCalendar]);
  }
};

export const generateEvents = (
  calendar: any
) => {
  const today = DateTime.now();
  const events: any = [];

  const start: any = today
    .set({ day: 1 })
    .minus({ month: 3 });
  const end: any = today
    .set({ day: 30 })
    .plus({ month: 3 });

  const count = Math.abs(start.diff(end, 'weeks').weeks);

  for (let i = 1; i < count; i += 1) {
    for(let recurringEvent of calendar.recurringEvents){
      const uuid = faker.datatype.uuid();
      let startDate: DateTime = start
        .plus({ week: i })
        .set({
          hour: recurringEvent.hour,
          minute: recurringEvent.minute,
          weekday: recurringEvent.weekday
        });
      const endDate: DateTime = startDate.plus({ minute: recurringEvent.duration });

      const event: any = {
        id: uuid,
        startAt: startDate.toUTC().toString(),
        endAt: endDate.toUTC().toString(),
        summary: recurringEvent.title,
        color: calendar.color,
        allDay: (recurringEvent.duration == 1439) ? true : false,
        children: {
          daysView: <Event title={recurringEvent.title} label={calendar.label} startDate={startDate} endDate={endDate} />,
        },
        // style: {
        //   borderTop: `2px solid ${calendar.borderColor} !important`,
        // },
      };
      //console.log(event);
      events.push(event);
    }
  }

  return events;
}