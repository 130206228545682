import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Form, Button, Dropdown } from 'react-bootstrap';
import { FiHome, FiCalendar, FiLock, FiUser, FiMenu, FiSettings } from "react-icons/fi";
import { BiOutline } from "react-icons/bi";
import { useAppContext } from '../context/state';
import { CALENDARS, CALENDAR_HOME } from '../utils/helper';
import a2 from '../assets/img/a2.jpeg';
import a6 from '../assets/img/a6.jpeg';
import a8 from '../assets/img/a8.jpeg';
import a9 from '../assets/img/a9.jpeg';
import { ReactComponent as Sundial } from '../assets/img/sundial.svg';

export default function Layout({ children }:any) {
  const appContext = useAppContext();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => { setShowMenu(!showMenu); };


  
  return (
    <>
      <div>
        <div className="d-flex flex-row">
          <div>
            <div className="d-lg-none p-1 position-absolute" style={{
              zIndex: 1100,
            }}>
              <Button variant="white" onClick={()=> toggleMenu()} className="d-lg-none">
                <FiMenu />
              </Button>
            </div>
            <div className={"d-lg-none page-sidenav navbar-light no-shrink nav-expand animate fade align-items-start sticky fadeInLeft " + (showMenu ? "show d-block " : "d-none ")}>
              <div className={"sidenav modal-dialog"}
              >
                <div className="flex scrollable hover mt-lg-0">
                <div className="sidenav h-100 modal-dialog box-shadow pt-5 pt-lg-0">
                  <div className="nav-border b-primary">
                    <Navbar.Brand className="my-4 mx-5 d-flex">
                      <a href="/" className="d-flex align-items-center">
                        <Sundial width={120} />
                      </a>
                    </Navbar.Brand>
                    <Nav className="mt-3" defaultActiveKey="home" as="ul" 
                      onSelect={(eventKey:any, event?:any)=>{
                        appContext.setCurrentCalendar(eventKey);
                        setShowMenu(false);
                      }}>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDAR_HOME}>
                          <span className="nav-icon"><FiHome /></span>
                          <span className="nav-text">My Calendar</span>
                        </Nav.Link>
                      </Nav.Item>
                      <li className="nav-header hidden-folded"><span>Shared Calendars</span></li>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.acme_customer.label}>
                          <span className="nav-icon"><BiOutline size="0.9em" /></span>
                          <span className="nav-text mx-0">acme-customer</span>
                        </Nav.Link>
                      </Nav.Item>
                      <li className="nav-header hidden-folded"><span>Public Calendars</span></li>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.all_company.label}>
                          <span className="nav-icon"><FiCalendar size="0.9em" /></span>
                          <span className="nav-text mx-0">all-company</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.product.label}>
                          <span className="nav-icon"><FiCalendar size="0.9em" /></span>
                          <span className="nav-text mx-0">product</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.engineering.label}>
                          <span className="nav-icon"><FiCalendar size="0.9em" /></span>
                          <span className="nav-text mx-0">engineering</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.social.label}>
                          <span className="nav-icon"><FiCalendar size="0.9em" /></span>
                          <span className="nav-text mx-0">social</span>
                        </Nav.Link>
                      </Nav.Item>
                      <li className="nav-header hidden-folded"><span>Private Calendars</span></li>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.epd_managers.label}>
                          <span className="nav-icon"><FiLock size="0.9em" /></span>
                          <span className="nav-text mx-0">epd-managers</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.engr_managers.label}>
                          <span className="nav-icon"><FiLock size="0.9em" /></span>
                          <span className="nav-text mx-0">engr-managers</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.managers.label}>
                          <span className="nav-icon"><FiLock size="0.9em" /></span>
                          <span className="nav-text mx-0">managers</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.epd_pretax.label}>
                          <span className="nav-icon"><FiLock size="0.9em" /></span>
                          <span className="nav-text mx-0">epd-pretax</span>
                        </Nav.Link>
                      </Nav.Item>
                      <li className="nav-header hidden-folded"><span>Personal</span></li>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.jordan.label}>
                          <span className="nav-icon"><FiUser size="0.9em" /></span>
                          <span className="nav-text mx-0">Jordan Hendorson</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.sadio.label}>
                          <span className="nav-icon"><FiUser size="0.9em" /></span>
                          <span className="nav-text mx-0">Sadio Mane</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.thiago.label}>
                          <span className="nav-icon"><FiUser size="0.9em" /></span>
                          <span className="nav-text mx-0">Thiago Alcantara</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link eventKey={CALENDARS.karun.label}>
                          <span className="nav-icon"><FiUser size="0.9em" /></span>
                          <span className="nav-text mx-0">Karun Chandok</span>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-fill bg-white">
            <header className={"card px-lg-4 mb-0 py-2 pe-4"}>
              {(appContext.currentCalendar == CALENDAR_HOME) ? (
                <div className="d-flex">
                  {/* <h5 className="mb-0"><FiHome className="me-1 mb-1" size="0.9em" /> Home</h5> */}
                  <Form className="">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                      size="sm"
                    />
                  </Form>
                  </div>
              ) : (<>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0 pt-1">#{appContext.currentCalendar}</h5>
                  <div className="d-flex">
                    <Dropdown>
                      <Dropdown.Toggle className="no-caret me-2 px-2 py-1 border-0" variant="white">
                        <FiSettings />
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark">
                        <Dropdown.Item href="#/action-2">Manage Members</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Rename Calendar</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#/action-4">Unsubscribe Calendar</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="d-flex avatar-group">
                      <a href="#" className="avatar w-24 b-a b-white"><img src={a2} /></a><a href="#" className="avatar w-24 b-a b-white"><img src={a6} /></a><a href="#" className="avatar w-24 b-a b-white"><img src={a8} /></a><a href="#" className="avatar w-24 b-a b-white"><img src={a9} /></a>
                    </div>
                  </div>
                </div>
                
              </>)}
              
            </header>
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  );
}

